@import '@entur/layout/dist/styles.css';
@import '@entur/typography/dist/styles.css';
@import '@entur/grid/dist/styles.css';
@import '@entur/menu/dist/styles.css';
@import '@entur/loader/dist/styles.css';
@import '@entur/a11y/dist/styles.css';
@import '@entur/button/dist/styles.css';
@import '@entur/expand/dist/styles.css';
@import '@entur/icons/dist/styles.css';
@import '@entur/alert/dist/styles.css';
@import '@entur/tokens/dist/styles.css';
@import '@entur/dropdown/dist/styles.css';
@import '@entur/tooltip/dist/styles.css';
@import '@entur/form/dist/styles.css';
@import '@entur/chip/dist/styles.css';
@import '@entur/tab/dist/styles.css';
@import '@entur/table/dist/styles.css';
@import '@entur/button/dist/styles.css';
@import '@entur/form/dist/styles.css';

body {
    margin: 0;
    font-family: Nationale, serif;
}

.footer-home__text {
    font-weight: 300;

    font-size: 1rem;
    line-height: 1.5rem;
}

.primary-bg-color {
    background-color: #f8f8f8;
}

.entur-logo-image {
    width: 104px;
    height: auto;
    aspect-ratio: auto 102 / 32;
    overflow-clip-margin: content-box;
    overflow: clip;
    margin-left: 40px;
    margin-right: 12px;
}

.entur-logo-image-contrast {
    width: 160px;
    height: auto;
    aspect-ratio: auto 102 / 32;
    overflow-clip-margin: content-box;
    overflow: clip;
    margin: 4px -12px 0 12px;
}

.top-navigation-border {
    border-bottom: 2px solid #f3f3f3;
}

.top-navigation {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    padding: 1rem 1rem;
    /*padding-left: 4rem;*/
    transition: margin-left 0.1s ease-out;
    height: 7rem;
    z-index: 20;
}

ol.eds-breadcrumbs {
    margin: 0;
    padding: 0;
}

.product-card-details-link-medium-large {
    float: right;
}
.product-card-details-link-small {
    display: block;
    text-decoration: none;
    background-image: none;
    padding-top: 0.5rem;
}

.data-asset-documentation {
    all: revert;
}

.data-asset-documentation table {
    border-collapse: collapse;
    text-indent: initial;
}

.eds-link.data-card-container:focus,
.eds-link.data-card-container:focus-visible {
    display: block;
}

.data-product-card:hover {
    -webkit-transform: translateY(-0.25rem);
    transform: translateY(-0.25rem);
    box-shadow: 0.0625rem 0.1225rem 0.3675rem 0.0625rem rgba(0, 0, 0, 0.12);
}
.data-product-card:hover .eds-link {
    animation: eds-link-underline 0.3s ease-in;
}

.data-product-card {
    transition: box-shadow 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;

    -webkit-appearance: none;
    appearance: none;
    border: 0.0625rem solid #e9e9e9;
    border-radius: 0.25rem;
    box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.12);
    color: inherit;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    max-width: 1024px;
}
.data-product-card:before {
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 1rem 0 rgba(0, 0, 0, 0.1);
    display: block;
    transition: opacity 0.2s ease-in-out;
}

.highlighted-card {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 860px) {
    .documentation-tab {
        padding: 0.5rem;
    }

    .entur-logo-image {
        margin-left: 1rem;
        padding-top: 0.5rem;
    }
}
@media (max-width: 640px) {
    .documentation-tab {
        padding: 0.5rem 0.25rem;
    }

    .highlighted-card img {
        display: none;
    }
}

@media (min-width: 1200px) {
    .footer-bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}
